<template>
	<div class="page-container">
		<Head />
		<banner :banner="slidePic" :slide="slide"></banner>
		<!-- <div class="page-banner" :style="{backgroundImage:'url('+(slidePic?slidePic[0]:banner)+')'}">
			<div class="container banner-align-right">
				<div class="banner-text wow fadeInDown">
					<div class="ch">{{lang=='zh-cn'?slide.title:slide.title_en}}</div>
					<div class="en">{{lang=='zh-cn'?slide.titile_comment:slide.titile_comment_en}}</div>
					<img v-if="slide.title" src="../assets/image/down-arrow.png" alt="" />
				</div>
			</div>
		</div> -->
		<div :class="{'index-wrapper':true, 'font-en': lang!=='zh-cn'}">
			<div class="serve-panel wow fadeInDown" >
                <!-- <div class="section-head text-center">
                    <div class="h2">{{item.ltitle}}</div>				
                </div> -->
                <!-- <h2>{{item.title}}</h2> -->
                <!-- <p>{{ item.titile_comment }}</p> -->
                <div class="service-card flex_bd" v-if="!!item.desc">
                    <div class="service-cell">
						<div class="cmm-wrapper" v-html="lang==='zh-cn'?htmls:htmls_en"></div>
                        <!-- <ul class="service-list">
                            <li v-for="(ite, idx) in item.desc.split(';')" :key="idx">
                                {{ ite }}
                            </li>										
                        </ul> -->
                    </div>
                </div>
				<!-- <el-row :gutter="24" type="flex" class="pack-row">
					<el-col :xs="12" :sm="8" :md="8" :lg="6" v-for="(ite,idx) in item.pic.split(',')" :key="idx">
						<div class="pack-card hover-up">
							<div class="pack-photo scale">
								<img :src="ite" alt="" />
							</div>							
						</div>
					</el-col>
					<el-col :xs="12" :sm="8" :md="8" :lg="6">
						<div v-if="lang==='zh-cn'" class="pack-more-card">
							<div class="p-title">在找别的东西吗?我们可以帮忙。</div>
							<el-button type="info" plain>请求定制报价</el-button>
						</div>
						<div v-else class="pack-more-card">
							<div class="p-title">Looking for something else? We can help.</div>
							<el-button type="info" plain>Request a custom quote</el-button>
						</div>
					</el-col>
				</el-row> -->
            </div>
		</div>
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '../components/Head.vue'
	import Foot from '../components/Foot.vue'
	import banner from '@/components/banner.vue'
	// import Content from '../components/content.vue';
	import { mapState } from 'vuex';
	export default {
		name: 'ProductDetail',
		components: {
			Head,
			Foot,
			banner,
			// Content
		},
		data() {
			return {
				banner:require('../assets/image/serve_banner.jpg'),
				prod_id: this.$route.query.prod_id,
				item:{},
				htmls:"",
				htmls_en: ""
			};
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'webcontents':state=>state.app.webcontents,
				'current':state=>state.app.current
			}),
			thispage(){
				if(this.webcontents){
					if(!this.current){
						var vid =  this.webcontents.list.filter(item=>item.type == 'Products')[0].id
						// this.setCurrent(vid)
						return this.webcontents.list.filter(item=>item.id == vid)[0]
					}else{
						return this.webcontents.list.filter(item=>item.id == this.current )[0]
					}
				}else{
					return null
				}
			},
			slide(){
				let slide1 = null
				if(this.thispage){
					slide1 = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				}
				return (slide1 && slide1.length > 0) ?slide1[0]:'';
			},
			slidePic(){
				let slide1 = null
				if(this.thispage){
					slide1 = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				}
				return (slide1 && slide1.length > 0) ?slide1[0].pictures.split(','):null;
			},
			// item(){
			// 	var itemlist=this.thispage.items.filter(ite=>ite.item_type =='product_list')
			// 	// @ts-ignore
			// 	let idx = parseInt(this.prod_id);
			// 	console.log(JSON.stringify(itemlist[0].content[idx]))
			// 	return itemlist[0].content[idx]
			// }
		},
		created() {
			// if (!this.current || !this.prod_id){
			// 	this.$router.back()
			// }
			console.log(this.item)
			// console.log(JSON.stringify(this.thispage))
			if(this.prod_id){
				this.$axios.get("https://jesway.cn/api/product/get_product_info?Product_id="+this.prod_id).then(res=>{
					// console.log(JSON.stringify(res))
					this.item = {
						ltitle: res.data.product_name,
						desc: res.data.description,
						pic:res.data.pic_address
					}					
					// console.log(res.data.description)
					this.htmls = res.data.description? res.data.description.replace(/\[img](.*?)\[\/img]/g, '<img src="$1"></img>').replace(/\[b](.*?)\[\/b]/g, '<b>$1</b>').replace(/\[i](.*?)\[\/i]/g, '<i>$1</i>').replace(/\[u](.*?)\[\/u]/g, '<u>$1</u>').replace(/\[color=(.*?)](.*?)\[\/color]/g, '<font color="$1">$2</font>').replace(/\[size=(.*?)](.*?)\[\/size]/g, '<font size="$1">$2</font>') :''
					// console.log(this.htmls)
					this.htmls_en = res.data.description_en ? res.data.description_en.replace(/\[img](.*?)\[\/img]/g, '<img src="$1"></img>').replace(/\[b](.*?)\[\/b]/g, '<b>$1</b>').replace(/\[i](.*?)\[\/i]/g, '<i>$1</i>').replace(/\[u](.*?)\[\/u]/g, '<u>$1</u>').replace(/\[color=(.*?)](.*?)\[\/color]/g, '<font color="$1">$2</font>').replace(/\[size=(.*?)](.*?)\[\/size]/g, '<font size="$1">$2</font>') :''
					// console.log(htmls)
					// this.htmls = htmls
					// this.product_list = res.data.products

				})
			}
			// let itemlist=this.thispage.items.filter(ite=>ite.item_type =='product_list')
			// 	// @ts-ignore
			// let idx = parseInt(this.prod_id);
			// let content = itemlist[0].content
			// try{
			// 	content = JSON.parse(content)
			// 	if(!Array.isArray(content)){
			// 		content = [{desc: content,desc_en: itemlist[0].content_en}]											
			// 	}
			// } catch(e){
			// 	content = [{desc: content,desc_en: itemlist[0].content_en}]
			// }
			// this.item = content[idx]
		},
		methods: {
			buttonEevent1(url){
				if('http' in url){
					window.open(url, '_blank')
				}else{
					this.$router.push({ path:url})
				}
			}
		},
		mounted() {
			new this.$wow.WOW({live: false}).init();
		}
	}
</script>
<style>
	.cmm-wrapper {
		white-space: pre-wrap; 
		box-shadow: 4px 4px 8px #888;
		padding-left: 30px;
		padding-right: 10px;
		/* line-height: 28px;
		color: #000032;
		font-size: 20px; */
		h1 {
			font-size: 40px;
		}
		h2 {
			font-size: 32px;
		}
		h3 {
			font-size: 24px;
		}
		h4 {
			font-size: 18px;
		}
		h5 {
			font-size: 16px;
		}
	}
</style>
